<script>
export default {
  name: "PageTitle",
  computed:{
    page(){ return this.$root.page }
  },
}
</script>

<template>
  <div class="page-titles">
      <div class="row">
        <div class="col-md-5 align-self-center">
          <h4 class="text-themecolor">{{page.PageName||"-"}}</h4>
      </div>
      <div class="col-md-7 align-self-center text-right">
          <div class="d-flex justify-content-end align-items-center">
              <ol class="breadcrumb">
                  <li class="breadcrumb-item"><router-link :to="{name:'BoDashboard'}">Home</router-link></li>
                  <li v-if="page.PageName != 'Home'" class="breadcrumb-item">{{page.PageName||"-"}}</li>
                  <li v-if="page.PageName != 'Home'" class="breadcrumb-item active">{{page.ObjectName||"-"}}</li>
              </ol>
              <router-link v-if="page.Name && $route.params.id" :to="{name:page.Name}" class="btn btn-success d-none d-lg-block m-l-15">
                <i class="fas fa-list-ul m-r-15"></i>List {{page.ObjectName}}
              </router-link>
              <router-link v-if="page.AddItem && !$route.params.id" :to="{name:page.Name,params:{id:'add'}}" class="btn btn-info d-none d-lg-block m-l-15">
                <i class="fa fa-plus-circle  m-r-15"></i>Add {{page.ObjectName}}  
              </router-link>
          </div>
      </div>
      </div>
      
  </div>
</template>